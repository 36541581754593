/* eslint-disable complexity */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
  useRef,
  useMemo
} from "react";
import PropType from "prop-types";
import { useContent } from "../../contexts/ContentContext";
import { useMarketing } from "../../contexts/MarketingContext";
import mapCartToAffirmCheckout from "../../utils/affirmUtils/mapCartToAffirmCheckout/mapCartToAffirmCheckout";
import { PAYMENT_TYPES } from "../../constants/checkout";
import { CART_WARNINGS, EMPTY_CART, FATAL_WARNINGS } from "./constants";
import useUser from "../../hooks/useUser";
import {
  CART_UNABLE_TO_SELL_CART_LEVEL,
  CART_WG_ITEMS_DISALLOWED_ERROR_CART_LEVEL
} from "../../constants/strings";
import { useRouter } from "next/router";
import {
  getCartCheckoutError,
  getCartClearError,
  getCartErrorsFromUser,
  getCartLoadError,
  getCartMessagesFromUser,
  getCartTypeForRoute,
  getCartUpdateError,
  getItemQuantity,
  getOutOfStockMessage,
  getPaypalErrorMessage,
  getPromoError,
  isPaymentError,
  updateCartMessages,
  getAdjustCartErrorResponse
} from "./utils";
import hasErrorType from "../../utils/errors/hasErrorType";
import apiClient from "../../utils/api/apiClient";
import isAuthError from "../../utils/errors/isAuthError";
import useSessionStorage from "../../hooks/useSessionStorage";
import getNetsuiteIdFromCart from "../../utils/getNetsuiteIdFromCart/getNetsuiteIdFromCart";
import groupCartWarningsByCode from "../../utils/groupCartWarningsByCode/groupCartWarningsByCode";
import useApplePay from "@serenaandlily/hooks/useApplePay";
import { PAGE_TYPE } from "@serenaandlily/constants/analytics/pageType";
import { removeDuplicateWarnings } from "@utils/removeDuplicateWarnings";
import fetchRemoveUnavailableItems from "@serenaandlily/gql/utils/fetchRemoveUnavailableItems";

// Warning codes whose messages come from amplience
const customWarningCodes = new Set([...FATAL_WARNINGS]);

const defaultValue = [];
const CartContext = createContext(defaultValue);
const CartUpdateContext = createContext();

// eslint-disable-next-line max-lines-per-function
const CartProvider = ({ children, defaultCart = EMPTY_CART }) => {
  // this entity needs to match backend entity
  // mutuations and queries that return Cart entity need to update cart
  // if the cart entity that is returned is not the full cart, we need to merge entities into the cart
  const [cart, setCart] = useReducer((state, newState) => {
    return { ...state, ...newState };
  }, defaultCart);
  const [authAttempts, setAuthAttempts] = useState(0);
  const [orderCart, setOrderCart] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatingId, setUpdatingId] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [estimateToCartWarnings, setEstimateToCartWarnings] = useState([]);
  const [cartErrors, setCartErrors] = useState(null);
  const [isDoNotShip, setIsDoNotShip] = useState(false);
  // for (non-error) messages that display above cart
  const [cartMessagesStore, setCartMessagesStore] = useSessionStorage(
    "sl_cartMessages",
    []
  );
  const { handleApplePay } = useApplePay();
  const [cartMessages, setCartMessages] = useState(cartMessagesStore);
  // messages specific to customer moving estimate to cart
  const [estimateErrors, setEstimateErrors] = useState(null);
  // messages specific to customer adding product to bag
  const [addToBagHeader, setAddToBagHeader] = useState(null);
  // boolean to hide panel from certain pages
  const [showAddToBag, setShowAddToBag] = useState(true);
  // messages specific to crossell section in AddToBag panel
  const [addToBagCrossellHeader, setAddToBagCrossellHeader] = useState(null);
  // messages specific for empty bag in Bag panel
  const [emptyBagMessage, setEmptyBagMessage] = useState(null);

  // 8x8 error messages
  const [recordingActionError, setRecordingActionError] = useState("");

  const { messages, promoCode } = useContent();

  // General errors from cart that will be shown to the user
  const generalWarningCodes = new Set([
    CART_WARNINGS.CART_ERROR_GIFT_INFO,
    CART_WARNINGS.CART_CHECKOUT_MISSING_CUSTOMER,
    CART_WARNINGS.CART_CHECKOUT_MISSING_PAYMENT,
    CART_WARNINGS.CART_UNABLE_TO_SELL,
    CART_WARNINGS.CART_WG_ITEMS_DISALLOWED_ERROR,
    CART_WARNINGS.CART_ERROR_LOAD,
    CART_WARNINGS.CART_ITEM_ERROR_MISSING_OPTIONS,
    CART_WARNINGS.CART_PROMO_NOT_REMOVED
  ]);

  const customWarningMessages = useMemo(
    () => ({
      [CART_WARNINGS.CART_UNABLE_TO_SELL]:
        messages?.cartUnableToSellCartLevel || CART_UNABLE_TO_SELL_CART_LEVEL,
      [CART_WARNINGS.CART_WG_ITEMS_DISALLOWED_ERROR]:
        messages?.cartWgItemsDisallowedErrorCartLevel ||
        CART_WG_ITEMS_DISALLOWED_ERROR_CART_LEVEL
    }),
    [messages]
  );

  const isGeneralWarning = (warning) => generalWarningCodes.has(warning?.code);

  const generalWarning = useMemo(
    () =>
      cart?.warnings?.length
        ? removeDuplicateWarnings?.(cart?.warnings)?.filter(isGeneralWarning)
        : [],
    [cart?.warnings]
  );

  // CART_UNABLE_TO_SELL has the possibility of being a billing only warning
  // This flag unblocks the shipping step if there is only billing warnings
  const hasFatalShippingWarnings = useMemo(
    () =>
      cart?.warnings
        ?.filter?.((warning) => FATAL_WARNINGS?.has?.(warning?.code))
        ?.filter?.(
          (warning) =>
            !(
              warning?.code === CART_WARNINGS?.CART_UNABLE_TO_SELL &&
              warning?.middlewareMessage?.includes?.("billing")
            )
        )?.length > 0,
    [cart?.warnings]
  );

  const takeActionWarning = useMemo(
    () =>
      !!cart?.warningsDictionary?.[CART_WARNINGS.CART_ERROR_INVENTORY] ||
      cart?.cartItems?.find((item) => item?.unavailable),
    [cart?.warningsDictionary, cart?.cartItems]
  );

  const takeActionWarningCheckout = useMemo(
    () => !!cart?.warningsDictionary?.[CART_WARNINGS.CART_ERROR_INVENTORY],
    [cart?.warningsDictionary]
  );
  // flag for addTocart used to avoid lagging when loading the addedBagPanel
  const [isAddLoading, setIsAddLoading] = useState(false);

  // user lookup
  const { user, mutateUser } = useUser();
  const previousUser = useRef(null);
  const { handleTrackPurchase, handleTrackAddToCart, fbBuildProductEvent } =
    useMarketing();
  const router = useRouter();

  const clearErrors = useCallback(() => {
    setCartErrors(null);
  }, []);

  const clearEstimateErrors = useCallback(() => {
    setEstimateErrors(null);
  }, []);

  const clearMessages = useCallback(() => {
    setCartMessages([]); // remove all messages from state
    setCartMessagesStore([]);
  }, []);

  const clearAllMessagesAndErrors = useCallback(() => {
    clearErrors();
    clearEstimateErrors();
    clearMessages();
  }, []);

  const processCartWarnings = useCallback(
    ({ data }) => {
      if (data?.cart) {
        if (
          typeof data.cart.warnings === "string" &&
          data.cart.warnings.length > 0
        ) {
          data.cart.warnings = JSON.parse(data.cart.warnings);

          // Override custom warning code middleware messages with amplience messages
          for (let i = 0; i < data?.cart?.warnings?.length; i++) {
            const warning = data?.cart?.warnings?.[i] || {};
            if (!customWarningCodes?.has?.(warning?.code)) continue;
            // Save middleware message since it is the only way to tell
            // if a warning is for billing or shipping
            warning.middlewareMessage = warning?.message;
            warning.message = customWarningMessages?.[warning?.code];
          }

          data.cart.warningsDictionary = groupCartWarningsByCode(
            data.cart.warnings
          );
        }
      }
      return data;
    },
    [customWarningMessages]
  );

  const fetchCart = useCallback(async (cartType) => {
    clearErrors();
    setIsLoading(true);
    let { data, errors } = await apiClient.get("/api/cart", {
      params: { view: cartType || "default" }
    });

    if (!isAuthError(errors)) {
      if (data?.cart) {
        // update cart in state
        data = processCartWarnings({ data, cart });
        setCart(data.cart);
      } else {
        setCartErrors({ route: "/api/cart", errors });
      }

      setIsLoading(false);
      setIsLoaded(true);
    }
  }, []);

  const clearCart = useCallback(() => {
    setCart(EMPTY_CART);
    clearAllMessagesAndErrors();
  }, []);

  // On mount or route change
  useEffect(() => {
    if (sessionStorage?.getItem("order")) {
      setOrderCart(JSON.parse(sessionStorage?.getItem("order")));
    }

    fetchCart(getCartTypeForRoute());
  }, [router.pathname]);

  // runs on cart update
  // eslint-disable-next-line complexity
  useEffect(() => {
    const {
      appliedPromotions,
      cartItems,
      cartItemsById,
      deliveryGroups,
      hasCart,
      itemCount,
      priceSummary,
      shippingItems
    } = cart;

    sessionStorage.setItem(
      "cart",
      JSON.stringify({
        appliedPromotions,
        cartItems,
        cartItemsById,
        deliveryGroups,
        hasCart,
        itemCount,
        priceSummary,
        shippingItems
      })
    );
  }, [cart]);

  // On message load
  useEffect(() => {
    if (messages && user) {
      const cartMessagesFromUser = getCartMessagesFromUser(user, messages);
      if (cartMessagesFromUser) {
        setCartMessages(updateCartMessages(cartMessagesFromUser));
        setCartMessagesStore(updateCartMessages(cartMessagesFromUser));
      }
      const estimateErrorFromUser = getCartErrorsFromUser(user, messages);
      if (estimateErrorFromUser) {
        setEstimateErrors(estimateErrorFromUser);
      }
    }
  }, [messages, user]);

  // Once user has loaded, listen for changes to the cartId on the user.
  // If user's cart id is cleared, we should clear cart in state
  // if user cart id is not the same as the cart in state, then we should update state
  useEffect(() => {
    if (
      previousUser.current &&
      previousUser.current.data?.cartId !== user.data?.cartId
    ) {
      if (!user.data?.cartId) {
        clearCart();
      } else if (user.data?.cartId !== cart.cartId) {
        fetchCart(getCartTypeForRoute());
      }
      clearAllMessagesAndErrors();
    }
    previousUser.current = user;
  }, [user, cart]);

  const handleCartAdd = useCallback(
    async ({
      quantity,
      productId,
      closePopup,
      source,
      customUpholsteryOptions,
      items,
      price,
      productName,
      netsuiteId,
      netsuiteIds = []
    }) => {
      clearErrors();
      const { email, firstName, lastName } = user?.data || {};
      if (source !== PAGE_TYPE.xsells) {
        setIsLoading(true);
      }
      setUpdatingId(productId);

      let { data, errors } = await apiClient.post("/api/cart/addItems", {
        quantity,
        productId,
        customUpholsteryOptions,
        items
      });
      if (!isAuthError(errors)) {
        if (data?.cart) {
          // update cart in state
          data = processCartWarnings({ data, cart });
          setCart(data.cart);

          // optionally, close modal
          if (closePopup) closePopup();
          handleTrackAddToCart(data.cart, user?.data?.email || "");
          const variantNetsuiteId = getNetsuiteIdFromCart({
            cartItems: data?.cart?.cartItems,
            productId,
            customUpholsteryOptions,
            netsuiteId
          });
          fbBuildProductEvent({
            email,
            firstName,
            lastName,
            productId: variantNetsuiteId,
            productName,
            price,
            facebookEvent: "AddToCart"
          });

          // TODO: here? add event for cart add?
          [netsuiteId]
            .concat(netsuiteIds)
            .filter((id) => id)
            .forEach((id) => {
              globalThis?.document?.body?.dispatchEvent(
                new CustomEvent("a-cart-add", {
                  detail: {
                    netsuiteId: id,
                    action: {
                      source
                    }
                  }
                })
              );
            });
        } else {
          setCartErrors({ route: "/api/cart/addItems", errors });
        }
        setIsLoading(false);
        setUpdatingId("");
      }
    },
    [user]
  );

  const handleCartAdjust = useCallback(
    async ({ cartItemId, productId, quantity }) => {
      setIsLoading(true);
      clearAllMessagesAndErrors();
      let response;
      let { data, errors } = await apiClient.post(
        "/api/cart/updateItemQuantity",
        {
          quantity,
          productId,
          cartItemId
        }
      );
      if (!isAuthError(errors)) {
        if (data?.cart) {
          // update cart in state
          data = processCartWarnings({ data, cart });
          setCart(data?.cart);
          response = { quantity: getItemQuantity(data?.cart, cartItemId) };
        } else {
          response = getAdjustCartErrorResponse({
            errors,
            cart,
            cartItemId,
            data,
            productId
          });
          setCartErrors({ route: "/api/cart/updateItemQuantity", errors });
        }

        setIsLoading(false);
        return response;
      }
    },
    [cart]
  );

  const handleCartItemEdit = useCallback(
    async ({
      cartItemId,
      quantity,
      productId,
      closePopup,
      initialProductId
    }) => {
      clearAllMessagesAndErrors();
      setIsLoading(true);

      let { data, errors } = await apiClient.post("/api/cart/updateItem", {
        quantity,
        cartItemId,
        productId,
        initialProductId
      });
      if (!isAuthError(errors)) {
        if (data?.cart) {
          // update cart in state
          data = processCartWarnings({ data, cart });
          setCart(data.cart);

          // optionally, close modal
          if (closePopup) closePopup();

          handleTrackAddToCart(data.cart, user?.data?.email || "");
        } else {
          setCartErrors({ route: "/api/cart/updateItem", errors });
        }
      } else {
        setCartErrors({ route: "/api/cart/updateItem", errors });
      }

      setIsLoading(false);
    },
    [user]
  );

  const handleCartAddressUpdate = useCallback(
    async (shippingFormData, isFromSubmit) => {
      setIsLoading(true);
      clearErrors();
      let { data, errors } = await apiClient.post("/api/checkout/shipping", {
        ...shippingFormData,
        isPayAndCarry: cart?.isPayAndCarry
      });
      if (!isAuthError(errors)) {
        if (data?.cart) {
          if (isFromSubmit) {
            data = processCartWarnings({ data, cart });
          } else {
            data.cart.warnings = cart?.warnings;
          }
          setCart(data?.cart);
        } else {
          setCartErrors({ route: "/api/checkout/shipping", errors });
        }

        setIsLoading(false);
        return data;
      }
    },
    [cart]
  );

  const handleCartShippingUpdate = useCallback(
    async (shippingFormData) => {
      clearErrors();
      let { data, errors } = await apiClient.post("/api/checkout/shipping", {
        ...shippingFormData,
        updateCartShipping: true
      });

      if (!isAuthError(errors) && data?.cart) {
        data = processCartWarnings({ data, cart });
        setCart(data?.cart);
      } else {
        setCartErrors({ route: "/api/checkout/shipping", errors });
      }
    },
    [cart]
  );

  // submit to BFF so that we can validate token before saving payment data
  const handleCartPayments = useCallback(
    // eslint-disable-next-line complexity
    async ({ paymentInfo, billingAddress, recaptchaData }) => {
      setIsLoading(true);
      clearErrors();
      if (paymentInfo.paymentAmount === "-")
        paymentInfo.paymentAmount = "$0.00";
      let { data, errors } = await apiClient.post("/api/checkout/billing", {
        paymentInfo,
        isPayAndCarry: cart?.isPayAndCarry,
        billingAddress,
        recaptchaData
      });
      if (!isAuthError(errors)) {
        if (data?.approveUrl) {
          return data;
        }
        if (data?.cart) {
          data = processCartWarnings({ data, cart });
          setCart(data.cart);
        } else {
          setCartErrors({ route: "/api/checkout/billing", errors });
          // if we have a partial update, then update state
          if (errors?.cart) {
            setCart(errors?.cart);
          }
        }
        setIsLoading(false);
        setIsPaymentLoading(false);
        return data;
      }
    },
    [cart]
  );

  const handleCartCheckout = useCallback(
    async ({
      validationCode = "",
      savePaymentMethod = "",
      savePaymentMethodAsDefault = "",
      email = "",
      cartId = ""
    }) => {
      setIsLoading(true);
      clearErrors();

      const { data, errors } = await apiClient.post("/api/checkout/order", {
        customer: {
          id: cartId || cart?.cartId,
          email: email || cart?.email
        },
        isPayAndCarry: cart?.isPayAndCarry,
        validationCode,
        savePaymentMethod,
        savePaymentMethodAsDefault
      });
      if (!isAuthError(errors)) {
        if (data?.order) {
          sessionStorage?.setItem("orderId", data?.order?.orderId);
          sessionStorage?.setItem(
            "order",
            JSON.stringify({
              ...data?.order,
              ...(data?.orderDOO && { orderDOO: data.orderDOO })
            })
          );
          setOrderCart(data?.order);
          handleTrackPurchase(data?.order, user?.data);
          router.push("/order-confirmation");
        } else {
          setCartErrors({ route: "/api/checkout/order", errors });
          // increment attempts if this is payment failure
          if (isPaymentError(errors)) {
            setAuthAttempts((attempts) => attempts + 1);
          }
          setIsLoading(false);
        }
        return { data, errors };
      }
    },
    [cart?.email, user]
  );

  const setAffirmToken = useCallback(
    async (affirmCheckoutToken) => {
      await handleCartPayments({
        paymentInfo: {
          type: PAYMENT_TYPES.AFFIRM,
          paymentAmount: cart?.priceSummary?.totalPrice,
          paymentToken: affirmCheckoutToken
        }
      });
      await handleCartCheckout({});
    },
    [handleCartCheckout, handleCartPayments, cart]
  );

  const handleAffirmCheckout = useCallback(async () => {
    setIsLoading(true);
    window?.affirm?.checkout?.(mapCartToAffirmCheckout(cart));
    window?.affirm?.checkout?.open?.({
      onFail: () => {
        setIsLoading(false);
      },
      onSuccess: async (data) => {
        await setAffirmToken(data?.checkout_token);
      }
    });
  }, [cart, setAffirmToken]);

  /**
   * Call start Paypal Order service and if we get a success response,
   * redirect to the Paypal site.
   * @type {(function({source: *}): Promise<void>)|*}
   */
  const handleInitializePaypal = useCallback(
    async ({ source, billingAddress }) => {
      setIsLoading(true);
      clearErrors();
      const { data, errors } = await apiClient.post("/api/checkout/paypal", {
        source,
        billingAddress
      });

      if (!isAuthError(errors)) {
        if (data?.approveUrl) {
          window.location.href = data.approveUrl;
          return;
        } else {
          setCartErrors({ route: "/api/checkout/paypal", errors });
        }
        setIsPaymentLoading(false);
        setIsLoading(false);
      }
    },
    []
  );

  /**
   * Call start Apple pay service
   * @type {(function({source: *}): Promise<void>)|*}
   */
  const handleInitializeApplePay = useCallback(
    async ({
      billingAddress,
      isBillingSameAsShipping,
      addressValidationEnabled,
      checkoutDataOverride
    }) => {
      setIsLoading(true);
      setIsPaymentLoading(false);
      clearErrors();
      const cartData = {
        ...cart,
        ...(checkoutDataOverride.email && {
          email: checkoutDataOverride.email
        }),
        ...(checkoutDataOverride.phoneNumber && {
          phoneNumber: checkoutDataOverride.phoneNumber
        }),
        ...(isBillingSameAsShipping && {
          billingAddress: checkoutDataOverride.shippingAddress
        })
      };

      handleApplePay(
        cartData,
        null,
        handleCartCheckout,
        setIsLoading,
        isBillingSameAsShipping,
        billingAddress,
        addressValidationEnabled
      );
    },
    [cart]
  );

  const cartLoadError = useMemo(() => {
    return getCartLoadError(cartErrors, messages);
  }, [cartErrors, messages]);

  // eslint-disable-next-line complexity
  const cartExists = useCallback(() => {
    return cart.cartId && !cartLoadError;
  }, [cartLoadError]);

  // eslint-disable-next-line complexity
  const handleNoExistingCartRedirect = useCallback(() => {
    if (!window?.location) return;
    if (cartExists()) return;
    clearErrors();
    window.location.href = "/shoppingbag";
  }, [cartExists]);

  const handleUpdateCart = useCallback(
    async (payload, cb) => {
      try {
        setIsLoading(true);
        clearAllMessagesAndErrors();
        let { data, errors } = await apiClient.post("/api/cart/updateCart", {
          ...payload
        });
        if (!isAuthError(errors)) {
          if (data?.cart) {
            data = processCartWarnings({ data, cart });
            setCart(data?.cart);
            const nextRoute =
              user?.data?.isDSO && !user?.data?.customerId
                ? "/customer-search?onSuccess=checkout"
                : "/checkout";
            if (cb) {
              cb();
              setIsLoading(false);
            } else {
              router.push(nextRoute);
            }
          }
          if (
            hasErrorType({
              errors,
              errorType: [
                "CART_ERROR_PNC_NOT_DSO",
                "CART_ERROR_PNC_CART_INELIGIBLE"
              ]
            })
          ) {
            setIsLoading(false);
            router.push("/shoppingbag");
          }
        }
      } catch (e) {
        setIsLoading(false);
      }
    },
    [cart, clearAllMessagesAndErrors, router, user]
  );

  const handleAddPromo = useCallback(async (couponCode) => {
    setIsLoading(true);
    clearAllMessagesAndErrors();
    let { data, errors } = await apiClient.post("/api/cart/addPromotion", {
      couponCode
    });
    if (!isAuthError(errors)) {
      if (data?.cart) {
        data = processCartWarnings({ data, cart });
        setCart(data?.cart);
        if (data?.cart?.warnings) {
          setCartErrors({
            route: "/api/cart/addPromotion",
            warnings: data?.cart?.warnings
          });
        }
      } else {
        setCartErrors({ route: "/api/cart/addPromotion", errors });
      }
      setIsLoading(false);
    }
  }, []);

  const handleRemovePromo = useCallback(async (couponCode) => {
    clearAllMessagesAndErrors();
    setIsLoading(true);

    let { data, errors } = await apiClient.post("/api/cart/removePromotion", {
      couponCode
    });
    if (!isAuthError(errors)) {
      if (data?.cart) {
        data = processCartWarnings({ data, cart });
        setCart(data?.cart);
      } else {
        setCartErrors({ route: "/api/cart/removePromotion", errors });
      }
      setIsLoading(false);
    }
  }, []);

  const handleClearCartItems = useCallback(() => {
    return setCart({ ...cart, cartItems: [] });
  }, []);

  const handleClearCart = useCallback(
    async ({ closePopup }) => {
      setIsLoading(true);
      clearErrors();
      await apiClient.post(
        "/api/customer/selectCustomer",
        { customer: { isAnonymous: false } },
        {
          headers: { "Content-Type": "application/json" }
        }
      );
      const { data, errors } = await apiClient.post("/api/cart/clearCart");
      if (data) {
        mutateUser({ data }, false);
        clearCart();
        if (closePopup) closePopup();
      } else {
        setCartErrors({
          route: "/api/cart/clearCart",
          errors
        });
      }
      setIsLoading(false);
    },
    [clearCart]
  );

  const handleCartView = useCallback(async () => {
    setCartMessagesStore([]);
    await apiClient.post("/api/user", {
      action: "CART_VIEW"
    });
  }, []);

  const handleRemoveUnavailableItems = useCallback(
    async (callback) => {
      setIsLoading(true);
      await fetchRemoveUnavailableItems(cart.cartId);
      setIsLoading(false);
      if (callback) callback;
    },
    [cart.cartId]
  );

  // Memoized Error Messages use selectors to get message based on cartError state.
  // Depending on type of error, we may choose to display message in different locations,
  // so we have a few error message props that will populate based on cartErrors sate
  const cartUpdateError = useMemo(() => {
    return getCartUpdateError(cartErrors, messages);
  }, [cartErrors, messages]);
  const cartCheckoutError = useMemo(() => {
    return getCartCheckoutError(cartErrors, messages, authAttempts);
  }, [cartErrors, messages, authAttempts]);
  const paypalErrorMessage = useMemo(() => {
    return getPaypalErrorMessage(cartErrors, messages);
  }, [cartErrors, messages]);
  const cartClearError = useMemo(() => {
    return getCartClearError(cartErrors, messages);
  }, [cartErrors, messages]);
  const cartOutOfStockMessage = useMemo(() => {
    return getOutOfStockMessage(cartErrors, messages);
  }, [cartErrors, messages]);
  const cartPromoError = useMemo(() => {
    return getPromoError(cartErrors, promoCode);
  }, [cartErrors, messages]);
  const cartPageErrors = useMemo(() => {
    return (
      !isLoading &&
      (cartClearError ||
        cartLoadError ||
        cartUpdateError ||
        paypalErrorMessage ||
        cartOutOfStockMessage,
      estimateErrors)
    );
  }, [
    isLoading,
    cartClearError,
    cartLoadError,
    cartUpdateError,
    paypalErrorMessage,
    cartOutOfStockMessage,
    estimateErrors
  ]);

  // memoized context provider value
  const cartContextValue = useMemo(() => {
    return { ...cart, isLoaded };
  }, [cart, isLoaded]);

  return (
    <CartContext.Provider value={cartContextValue}>
      <CartUpdateContext.Provider
        value={{
          cartCheckoutError,
          cartClearError,
          cartErrors,
          cartLoadError,
          cartMessages,
          cartOutOfStockMessage,
          cartPageErrors,
          cartPromoError,
          cartUpdateError,
          estimateToCartWarnings,
          isPaymentLoading,
          hasFatalShippingWarnings,
          processCartWarnings,
          clearCart,
          clearErrors,
          clearMessages,
          estimateErrors,
          fetchCart,
          handleUpdateCart,
          handleAddPromo,
          handleAffirmCheckout,
          handleCartAdd,
          handleCartAddressUpdate,
          handleCartAdjust,
          handleRemoveUnavailableItems,
          handleCartCheckout,
          handleCartItemEdit,
          handleCartPayments,
          handleCartShippingUpdate,
          handleCartView,
          handleClearCart,
          handleClearCartItems,
          handleInitializePaypal,
          handleInitializeApplePay,
          handleNoExistingCartRedirect,
          handleRemovePromo,
          isLoaded,
          isLoading,
          orderCart,
          generalWarning,
          takeActionWarning,
          takeActionWarningCheckout,
          paypalErrorMessage,
          setCartMessages,
          updatingId,
          setAddToBagHeader,
          setIsLoading,
          setIsPaymentLoading,
          setEstimateToCartWarnings,
          addToBagHeader,
          showAddToBag,
          setShowAddToBag,
          addToBagCrossellHeader,
          setAddToBagCrossellHeader,
          emptyBagMessage,
          setEmptyBagMessage,
          isAddLoading,
          setIsAddLoading,
          isDoNotShip,
          setIsDoNotShip,
          setCart,
          recordingActionError,
          setRecordingActionError
        }}
      >
        {children}
      </CartUpdateContext.Provider>
    </CartContext.Provider>
  );
};

function useCart() {
  const context = useContext(CartContext);
  if (context) {
    return context;
  } else {
    //eslint-disable-next-line  no-console
    console.warn("Missing CartProvider for useCart");
    return {};
  }
}

function useCartUpdate() {
  const context = useContext(CartUpdateContext);
  if (context) {
    return context;
  } else {
    //eslint-disable-next-line  no-console
    console.warn("Missing CartProvider for useCartUpdate");
    return {};
  }
}

export { useCart, useCartUpdate, CartProvider };

CartProvider.propTypes = {
  children: PropType.any,
  defaultCart: PropType.object,
  isCartPage: PropType.bool
};
